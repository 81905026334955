<template>
  <div>
    <!--
    <h1>{{ name }}</h1>
    <router-link to="/admin">Admin</router-link>
    -->
  </div>
</template>

<script>
export default {
  name: 'Frontpage',
  data() {
    return {
      name: 'Frontpage',
    };
  },
  mounted() {},
};
</script>
